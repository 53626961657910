import { first_scene } from '../fade.js'

let about_div;

export function initAbout(){
  about_div = document.getElementById('about-wrapper')
  initMouseEvents()
}

function initMouseEvents(){
  about_div.addEventListener( 'mousemove', mouseMoveAction )
}

export function removeMouseEvents(){
  //console.log('removing mousemove')
  about_div.removeEventListener( 'mousemove', mouseMoveAction )
}

function mouseMoveAction( e ){

  first_scene.mouseEffect.x = e.clientX - first_scene.sizes.width * .5;
  first_scene.mouseEffect.y = first_scene.sizes.height * .5 - e.clientY;
}