import './style.css';
import Highway from '@dogstudio/highway';
import Fade from './fade.js';
import AppRenderer from './load';
import AboutRenderer from './load_about'
import { first_scene } from './fade.js';

/**
 * Highway Page transitions
 */
 const H =  new Highway.Core({
	renderers: {
		 apps: AppRenderer,
		 about: AboutRenderer
	},
	transitions: {
		default: Fade
	}
})

// Listen to the "NAVIGATE_IN" event
// Event is sent every time a data-router-view is added to the DOM tree
H.on( 'NAVIGATE_IN', ( { to, location } ) => {
	updateLinkStatus( location )

})

// Run script on the new page
// This event is sent every time the done() method is called in the in() method of a transition
H.on('NAVIGATE_END', ( { to, from, trigger, location } ) => {

})

export const links = document.querySelectorAll('nav a');

/**
 * Updates nav style
 * @param {*} location url
 */
function updateLinkStatus ( location ) {

	if ( location.pathname === '/index.html' || location.pathname === '/about.html' ) {
		showCanvas()
	}
	
	links.forEach( ( link, i ) => {
		// remove active
		link.classList.remove('active-nav')
		// update active link
		if ( link.href === location.href ) {
			if( i !== 0 ){ // change only the link not logo
			link.style.color = '#35517a'
			}

			link.classList.add('active-nav')
			
		} else {
			// inactive links
			link.style.color = '#233245'
		}
	})
}

export const nav = document.getElementById('header')

export const SELECTED_NAV_COLOR = '#c8c2bc'

export const nav_links = document.querySelectorAll('nav a')

export const nav_links_wrapper = document.getElementById('nav-bar')

export const hero = document.getElementById('hero')

/**
 * Passes lerped color for app carousel
 * @param {String} color 
 */

export function updateHeaderFontColor( color ){
	nav.style.color = color
	nav_links.forEach( ( link, i ) => {
		if ( i === 2){
			link.style.color = SELECTED_NAV_COLOR
		} else { 
			link.style.color = color
		}
	})
	
}

export function updateNavFlexDirection( dir ){
	nav_links_wrapper.style.writingMode = dir
}

export function removeNavFlexDirection(){
	nav_links_wrapper.style.removeProperty('writing-mode')
}

export function hideCanvas(){
	//console.log('hide')
	first_scene.canvas.style.display = "none"
}

export function showCanvas (){
	//console.log('show')
	first_scene.canvas.style.display = "block"
}