import Highway from '@dogstudio/highway'
import { initAbout } from './about/about'
import { removeMouseEvents } from './about/about'

class AboutRenderer extends Highway.Renderer {

  // runs when the data-router-view is added to the DOM Tree
  onEnter(){
    //console.log('loading about.js')
    initAbout()
    
  }
  // runs when the transition to hide the data-router-view runs
  onLeave(){
   
    removeMouseEvents()
    
  }
  // runs when data-router-view is removed from the DOM tree
  onLeaveCompleted(){

  }
}

export default AboutRenderer;