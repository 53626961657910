import Highway from '@dogstudio/highway'
import { addMediaQueryEventHandler, removeMediaQueryListener,  stopStep, removeListeners, initApps } from './apps/script.js'
import { updateHeaderFontColor, removeNavFlexDirection } from './main.js'

// Look in main.js
// Runs when transitioning to apps

class AppRenderer extends Highway.Renderer {

  // runs when the data-router-view is added to the DOM Tree
  onEnter(){
    initApps()
    addMediaQueryEventHandler()
    // add mouse event for gallery scroll if it's not a touch device and desktop window sizes
  }
  // runs when the transition to hide the data-router-view runs
  onLeave(){
    removeMediaQueryListener()
    stopStep()
    //TODO: remove mouse and resize listeners
    removeListeners()
    
  }
  // runs when data-router-view is removed from the DOM tree
  onLeaveCompleted(){
    removeNavFlexDirection()
    updateHeaderFontColor( '#000000' )

  }
}

export default AppRenderer;