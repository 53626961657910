import Highway from '@dogstudio/highway'
import gsap from 'gsap';
import APP from './init_three.js'

export const first_scene = new APP() // three.js instance

class Fade extends Highway.Transition {
  /**
   * from- page we are leaving
   * to- page we are going to
   */
  in( { from, to, done } ){
    
    let next_page = to.getAttribute( 'data-router-view');
    // APP DOM created first before this line
    //console.log('next page', next_page)

    // show hero only in the beginning, hide for remainder
    if( next_page === 'home' ){
      hero.style.display = 'none'
    }

    let enter = gsap.timeline({
      paused: true,
    })
      enter
      .set('.page-transition-gold', {
        scaleX: 0,
      })
      .to( to, {
        opacity: 1,
        duration: .1,
        onComplete: () => {
          from.remove() // Remove the old content or current
          first_scene.goto(next_page)
        },
      })
      .to('.page-transition-black', {
        scaleX: 0,
        duration: 1.2,
        transformOrigin: 'left',
        ease: "power4.inOut",
        // onComplete: done
      })
      .to('.page-transition-logo', {
        autoAlpha: 0,
        duration: .2,
        onComplete: done
      }, "-=.6")

    enter.restart()

  }

  // page we are exiting
  out( { from, done } ){
    let prev_page = from.getAttribute( 'data-router-view');
    //console.log("out", prev_page)
    if( prev_page === 'apps'){
      // reveal canvas in case it does not self reveal when back button is selected 
      first_scene.canvas.style.visibility = 'visible'
    }
    // Animation
    let exit = gsap.timeline({
      paused: true,
    })
      exit
      .to('.page-transition-gold', {
        scaleX: 1,
        duration: 2.2,
        transformOrigin: 'right',
        ease: "power4.inOut",
      })
      .to('.page-transition-black', {
        scaleX: 1,
        duration: 2.2,
        transformOrigin: 'right',
        ease: "power4.inOut",
      }, .2)
      .to('.page-transition-logo', {
        xPercent: -50,
        autoAlpha: 1, 
        ease: "power4.inOut"
      }, 1.3)
      .fromTo( from, {
        opacity: 1
      }, {
        opacity: 0,
        duration: .1,
        onComplete: done
      })

      exit.restart()

  }
}

export default Fade;