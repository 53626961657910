import { Vector2 } from "three";

const RippleCustomPass = {

  uniforms: {
    'tDiffuse': { value: null }, // texture
    'time': { value: 0 },
    'uDisplacement': { value: null }
  },

  vertexShader: /* glsl */`

    varying vec2 vUv;

    void main() {
      vUv = uv;
                            
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,

  fragmentShader: /* glsl */`

    uniform sampler2D tDiffuse; // prev pass or image
    uniform sampler2D uDisplacement; // displacement map from ripple
    varying vec2 vUv;
    float PI = 3.141592653589793238;

    
    void main() {
          
      vec4 displacement = texture2D( uDisplacement, vUv ); // rgba

      float theta =  displacement.r * 2. * PI;

      vec2 dir = vec2( sin(theta), cos(theta) ); // unit vector

      vec2 uv = vUv + dir * displacement.r * 0.1;    
          
      // original image
      vec4 color = texture2D( tDiffuse, uv );

      gl_FragColor = color;
    
    }`
};

export { RippleCustomPass };