import * as THREE from 'three';
import { MathUtils } from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { CustomPass } from './CustomPass.js';
import { RippleCustomPass } from './RippleCustomPass.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import * as dat from 'lil-gui';
import appVertex from '../static/shader/app_vertex.glsl';
import appFragment from '../static/shader/app_fragment.glsl';
import gsap from 'gsap';
import './style.css';
import { contentAnimationById, lerpColor } from './apps/script';
import swirl_vertex from '../static/shader/swirl_vertex.glsl';
import swirl_fragment from '../static/shader/swirl_fragment.glsl';
import about_vertex from '../static/shader/plain_vertex.glsl';
import about_fragment from '../static/shader/plain_fragment.glsl';


// TODO 
// js style is temporary when dom elements are recreated

// fill in app details and bio
// optimize
// enable touch for tablets


export default class Me {
  constructor(){
    // SCENES
    this.scene = {
      home: new THREE.Scene(),
      app: new THREE.Scene(),
      about: new THREE.Scene(), // mouse
      aboutRipple: new THREE.Scene() // texture bg
    }

    this.scene.home.name = "home"
    this.scene.app.name = "app"
    this.scene.about.name = "about"

    // set bg
    this.scene.home.background = new THREE.Color( "#747474" )
    this.scene.app.background = new THREE.Color( '#ffffff' )
    this.scene.about.background = new THREE.Color( '#000000' )
    this.scene.aboutRipple.background = new THREE.Color( '#d4a971' )

    this.canvas = document.querySelector( 'canvas.webgl' );

    this.sizes = {
      width: window.innerWidth ,
      height: window.innerHeight,
      aspect: window.innerWidth / window.innerHeight,
      fov: 45, // FOV 40-75 is recommended,
      planeAspectRatio: 16 / 9,
      originalWidth: window.innerWidth,
      originalHeight: window.innerHeight
    };

    this.clock = new THREE.Clock();
    this.time = 0

    this.appGroup = new THREE.Group(), // app gallery

    // STAT GUI
    //this.stats = Stats();
    //document.body.appendChild(this.stats.dom)

    // RENDERER
    this.renderer = new THREE.WebGLRenderer( {
      canvas: this.canvas,
      powerPreference: 'high-performance', //hints what config of GPU is suitable
      //alpha: true // for transparent background
    } );
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.sizes.width, this.sizes.height);
    //this.renderer.gammaOutput = true
    //this.renderer.gammaFactor = 2.2
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.toneMapping = THREE.NoToneMapping;
    
    //this.renderer.outputEncoding = THREE.sRGBEncoding;
    //this.renderer.setClearColor(0x000000, 1)
    //this.renderer.physicallyCorrectLights = true


    // CAMERA
    this.camera = new THREE.PerspectiveCamera(
      this.sizes.fov, 
      this.sizes.aspect,
      0.1, // near
      100, //far
    );
    this.camera.position.set(0, 8.5, -7)
    this.camera.lookAt(0, 7, -2); 

    this.galleryCamera = new THREE.PerspectiveCamera(
      this.sizes.fov, 
      this.sizes.aspect,
      0.1, // near
      100, //far
    );

    // for screen space coordinates
    this.orthoCamera = new THREE.OrthographicCamera(
      this.sizes.height * this.sizes.aspect / - 2, 
      this.sizes.height * this.sizes.aspect / 2, 
      this.sizes.height / 2,
      this.sizes.height / - 2, 
      -1000, 
      1000 
    );
    this.orthoCamera.position.set( 0, 0, 2 );

    this.controls = new OrbitControls( this.camera, this.canvas );
    this.controls.enabled = false;
    this.isOrbitEnabled = false;

    // LIGHTS
    this.ambientLight= new THREE.AmbientLight(0xffffff, 0.5);
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
    this.directionalLight.castShadow = true;
    this.directionalLight.shadow.mapSize.set(1024, 1024);
    this.directionalLight.position.set(-2, 12, -1);

    this.scene.home.add( this.ambientLight )
    this.scene.home.add( this.directionalLight )


    // HELPERS
    //this.lightHelper = new THREE.DirectionalLightHelper( this.directionalLight, 5 );
    //this.scene.add( this.lightHelper )
    //this.cameraHelper = new THREE.CameraHelper( this.directionalLight.shadow.camera)
    //this.scene.home.add( this.cameraHelper )

    // POST PROCESSING
    this.baseTexture = new THREE.WebGLRenderTarget(
      this.sizes.width,
      this.sizes.height,
      {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat
      }
    )

    // EffectComposer( renderer : WebGLRenderer, renderTarget : WebGLRenderTarget )
    this.composer = {
      home: new EffectComposer( this.renderer ), // create composer
      app: new EffectComposer( this.renderer ),
      about: new EffectComposer( this.renderer, this.baseTexture ),
      aboutRipple: new EffectComposer( this.renderer ),
    }

    // antialising for jagged edges - SMAA supposed to be faster and better than FXAA
    this.pixelRatio = this.renderer.getPixelRatio();
    this.width_pixelRatio = this.sizes.width * this.pixelRatio
    this.height_pixelRatio = this.sizes.height * this.pixelRatio

    // set default scene
    this.activeScene = this.scene.home

    // assign composer to each scene
    this.scene.home.composer = this.composer.home
    this.scene.app.composer = this.composer.app
    this.scene.about.composer = this.composer.about

    // IMPORTED MODELS
    this.MODELS = [
      { name: 'laptop' },
      { name: 'desk', color: null }, 
      { name: 'deskleg', color: null },
      { name: 'succulent', color: null },
      { name: 'flowerpot' }, // flowerpot succulent
      { name: 'mug' }, // 5
      { texture: 'app1', path: '/images/1f.jpg'}, //6
      { texture: 'app2', path: '/images/2f.jpg'},
      { texture: 'app3', path: '/images/3f.jpg'},
      { texture: 'swirl', path: '/textures/swirl.jpg'}, //9 will have asset
      { texture: 'about', path: '/textures/floral.png'}, 
      { texture: 'brush', path: '/textures/brush.png'},
      { cube: 'cube' }
    ]

    this.macScreen = null;
    this.swirlPlane = null;
    this.showLaptopSwirl = false;

    // APPS Page
    this.appMaterials = [];
    this.appMeshes = [];
    this.galleryScroll = false; // was imgScroll
    this.scrollPoints = {
      wheelSpeed: 0,
      wheelPosition: 0,
      wheelRounded: 0,
    }
    this.appIndex = 0;
    this.prevIndex = 0;
    this.currentApp = 0;
    this.scale = 0;
    this.MIN = -.2;
    this.MAX = 2.2 // find sweet spot for the inertia to occur
    this.HEIGHT = 7
    this.GAP = 1.2;
    this.targetPosition = 7.0;
    this.moveScroll = false;
    this.incrementValue = 0;
    this.previousApp0Position = 7.0;
    // app section background colors
    this.appColors = {
      '0': '#ffffff',
      '1': '#fb476e', 
      '2': '#386ac8' 
    }
    this.appHeadingColors ={
      '0': '#27a3e4',
      '1': '#33facf', 
      '2': '#eae172' 
    }

    // Raycaster
    this.appRayCaster = new THREE.Raycaster()
    this.appRayMouse = {}

    // ABOUT ME PAGE
    this.enableSwirl = false;
    this.mouseEffect = new THREE.Vector2( 0, 0 )
    this.prevMouseEffect = new THREE.Vector2( 0, 0 )
    this.currentWave = 0;

    // LOADERS
    this.gltfloader = new GLTFLoader().setDRACOLoader(new DRACOLoader().setDecoderPath('draco/'));
    this.textureLoader = this.loadManager();

    this.cubeLoader = new THREE.CubeTextureLoader
    this.cubeLoader.setPath('textures/cube/darkest_sand_4096/') // directory that contains the maps

    this.cubeBG = [ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ]


    this.resize()
    //this.loadOrbitControls()
    this.loadAssets()

  } // end of constructor

  settings(){
  // DEBUGGER
    this.gui = new dat.GUI();
    this.settings = {
   //   ambient: 0,
   //   directional: 0
      progress: 0,
      scale: 0
   }
   this.gui.add( this.settings, "progress", 0, 5, 0.01);
   this.gui.add( this.settings, "scale", 0, 10, 0.01);
  //   this.gui.add( this.ambientLight, 'intensity').min(0).max(1).step(0.01);
  //   this.gui.add( this.directionalLight, 'intensity').min(0).max(1).step(0.01);
    
  }

  initExplore(){
    this.explore_button = document.getElementById('explore-home');

    this.explore_button.addEventListener('click', () => {
      //console.log('clicked')
      this.outro()
    })    
  }

  hideHero(){
    this.hero = document.getElementById('hero');
    this.hero.style.display = 'none';
  }

  loadOrbitControls(){
    // add listener
    this.controls.enablePan = false;
    this.controls.enableDamping = true;
    this.controls.enabled = true;
    // prevent controls below plane
    //this.controls.maxPolarAngle = Math.PI * .5
    
    // limit zoom out and in
    this.controls.maxDistance = 13
    this.controls.minDistance = 4
    // disable until needed, messes with camera controls on wheel
    //this.controls.enabled = false
    this.controls.target.set( 0, 6.3, 0 );

    // set camera back to initial position from orbit control's position
    this.camera.position.set(0, 8.5, -6)
    this.camera.lookAt(0, 7, -2); 
    this.isOrbitEnabled = true
  }

  loadManager(){
    const loadingManager = new THREE.LoadingManager();
    const loadingSvgElement = document.getElementById('hair-stroke')
    // The length of the stroke that changes as assets get loaded
    const STROKE_OFFSET = 1373;
  
    loadingManager.onLoad = () =>
    {
      //console.log('loading finished')
    }
  
    loadingManager.onProgress = ( itemUrl, itemsLoaded, itemsTotal ) =>
    {
      loadingSvgElement.style.strokeDashoffset = STROKE_OFFSET- (STROKE_OFFSET * (itemsLoaded/ itemsTotal))
    }
  
    loadingManager.onError = () =>
    {
     // console.log('loading error')
    }
    return new THREE.TextureLoader(loadingManager)
  }

  resize(){
    let that = this;
    window.addEventListener( 'resize', resize);

    function resize(){

      that.sizes.width = window.innerWidth;
      that.sizes.height = window.innerHeight;
      let actualRatio = that.sizes.width / that.sizes.height

      // wider
      //if ( actualRatio > that.sizes.planeAspectRatio ){
        // window is wide
        const cameraHeight = Math.tan( MathUtils.degToRad( that.sizes.fov / 2 ));
        const ratio = actualRatio / that.sizes.planeAspectRatio;
        const newCameraHeight = cameraHeight / ratio;
        const fov = MathUtils.radToDeg( Math.atan( newCameraHeight )) * 2;
        that.camera.fov = fov
        that.galleryCamera.fov = fov
      // } else {
      //   // taller
      //   that.camera.fov = that.sizes.fov
      // }

      that.renderer.setSize( that.sizes.width, that.sizes.height );
      that.renderer.setPixelRatio( Math.min( window.devicePixelRatio, 2 ) );
      that.camera.aspect = actualRatio;
      that.galleryCamera.aspect = actualRatio;
      that.camera.updateProjectionMatrix();
      that.galleryCamera.updateProjectionMatrix();

        
      that.orthoCamera.left = that.sizes.height * actualRatio / - 2
      that.orthoCamera.right = that.sizes.height * actualRatio / 2
      that.orthoCamera.top = that.sizes.height / 2
      that.orthoCamera.bottom = that.sizes.height / - 2
      that.orthoCamera.updateProjectionMatrix()
      that.composer.aboutRipple.setSize( that.sizes.width, that.sizes.height )

      //renderer
      that.activeScene.composer.setSize( that.sizes.width, that.sizes.height )
    }
    resize()
  }

  loadTexture( model ){
    if (model.name){
      return new Promise( resolve => {
        this.textureLoader.load( `/textures/${model.name}-color.jpg`, resolve )
      })
    }

    if( model.texture ) {
      return new Promise( resolve => {
        this.textureLoader.load( model.path, resolve )
      })
    }
  }

  loadGeometry( name ) {
    return new Promise( resolve => {
     this.gltfloader.load('models/Mac/' + name + '.glb', resolve)
    })
  }

  loadMesh( model ){
    const promises = []

    if ( "texture" in model ) {
      promises.push( this.loadTexture( model ) )

      return Promise.all( promises ).then( result => {
        model.asset = result[ 0 ]
      })
    }

    // load the cube box
    if ( "cube" in model ) {
      return new Promise( resolve =>  this.cubeLoader.load( this.cubeBG, resolve ) ) 
    }

    promises.push( this.loadGeometry( model.name ) )

    if ( "color" in model) {
      promises.push( this.loadTexture( model ) )

      return Promise.all( promises ).then( result => {
        model.scene = result[ 0 ].scene
        model.color = result[ 1 ]
        
      })
    } 

    if ( "name" in model ) {
      return Promise.all( promises ).then( result => {
        model.scene = result[ 0 ].scene
      })
    }

  }

  setMeshStyle( model ){
    model.scene.traverse( m => {
      
      // extract for animation
      if ( m.name === 'laptop-top' && !this.macScreen ){
        this.macScreen = m;
        //continue; Babel breaks
      } 
      if (m.name === 'swirl-plane' && !this.swirlPlane ){
        this.swirlPlane = m;
      }
      if ( m.isMesh ) {
        // cube055 is bottom
        if ( m.name === 'mug' || m.name === 'Cube055'  || m.name === 'flowerpot') {
          //console.log('m', m)
          m.castShadow = true;
          
        }
        m.material.format = THREE.RGBAFormat;
   
        // do not cast shadow from succulent, flowerpot, frame, leave shadow on desk that disappears and reappears
        if ( model.color ) {
          const material = ( model.name === 'desk' ) ? new THREE.MeshStandardMaterial() : new THREE.MeshBasicMaterial()
          //const material = new THREE.MeshStandardMaterial()
          model.color.encoding = THREE.sRGBEncoding; // 3001
          material.map = model.color;
  
          if ( model.name === 'desk' ) {
            m.receiveShadow = true;
            //m.castShadow = true;
          }
          if ( model.name === 'succulent' || model.name === 'deskleg') {
            model.color.flipY = false;
            m.castShadow = true;
            m.receiveShadow = false;
          }
          m.material = material
        }
      }
  
    })
  }

  groupModelsAndAddToScene( model, i ){
    const MODEL_END = 5
    if ( i > MODEL_END ) return

    if ( i > 0 && i < MODEL_END ) {
      model.matrixAutoUpdate = false; 
    } 
      this.scene.home.add( model );
    
  }

  loadAssets(){
    const promises = this.MODELS.map(( model, i ) => {
      return this.loadMesh( model )
      .then(( asset ) => {
        if(model.cube){
          // home background texture
          this.textureCube = asset
          this.textureCube.minFilter = THREE.LinearFilter;
          this.textureCube.magFilter = THREE.LinearFilter;
          this.textureCube.generateMipmaps = false
          this.textureCube.anisotropy = this.renderer.capabilities.getMaxAnisotropy()
          this.scene.home.background = this.textureCube
        }

        if (model.name){
          this.setMeshStyle( model )
          this.groupModelsAndAddToScene( model.scene, i )
        }
      })
    })

    Promise.all( promises ).then( () => {
      //////////////////
      /// ACTIONS AFTER LOAD
      //////////////////
      this.loadAppGallery()
      this.loadAboutSectionBG()
      this.loadLapTopSwirl()
      this.initPost()
      this.initExplore()
      //this.settings()

      // gsap animation
      this.intro()

      this.renderer.setAnimationLoop( () => {
        //this.stats.begin()
        this.time += 0.05;
      
        //const deltaTime = this.clock.getDelta()
      
        this.update( this.time )
        //this.cameraHelper.update()
        //this.controls.update()
        this.activeScene.composer.render();
        if (this.activeScene.name === "about"){
          this.composer.aboutRipple.render()
        }
       // this.stats.end()
      })
    })

  }

  //TODO: mouse ripple is inconsistent with window resizes

  loadAboutSectionBG(){
    // image
    this.aboutMaterial = this.setShaderMaterial( this.MODELS[10].asset, about_vertex, about_fragment )
    this.aboutPlane = this.setPlane( this.aboutMaterial, this.sizes.width , this.sizes.height , 1, 1 )

    this.aboutPlane.position.set( 0, 0, 0 )
    this.aboutPlane.rotation.z = Math.PI * .5
    this.scene.aboutRipple.add( this.aboutPlane )

    // setup brush effect
    this.maxBrushes = 100;
    this.brushMeshes = [];
    this.brushGeometry = new THREE.PlaneBufferGeometry(30, 30, 1, 1)
    
    for ( let i = 0; i < this.maxBrushes; i++) {
      // create material
      let m = new THREE.MeshBasicMaterial({
        // color: 0xff0000,
        map: this.MODELS[11].asset,
        transparent: true,
        blending: THREE.AdditiveBlending,
        depthTest: false,
        depthWrite: false
      })

      // create random brush positions
      let mesh = new THREE.Mesh( this.brushGeometry, m )
      mesh.visible = false; // or it will show initially before mouse moves
      mesh.rotation.z = 2 * Math.PI * Math.random();
      this.scene.about.add( mesh );
      this.brushMeshes.push( mesh );
    }

  }

  loadLapTopSwirl(){
    this.laptopSwirlMaterial = this.setShaderMaterial( this.MODELS[9].asset, swirl_vertex, swirl_fragment )
    this.laptopSwirlMaterial.uniforms.tSize = { value: new THREE.Vector2( 256, 256 ) }

    this.laptopSwirlMaterial.uniforms.center = { value: new THREE.Vector2( 0.5, 0.5 ) }
    
    this.laptopSwirlMaterial.uniforms.angle = { value: 1.57 }
  
    this.laptopSwirlMaterial.uniforms.scale = { type: "f", value : 2.}; 

    this.laptopSwirlMaterial.uniforms.progress = { type: "f", value: 1.5 }

    this.swirlPlane.material = this.laptopSwirlMaterial

    this.showLaptopSwirl = true

  }

  initPost(){   
    //takes rendered scene as input for next pass
    this.composer.home.addPass( new RenderPass( this.scene.home, this.camera ) )
    this.composer.app.addPass( new RenderPass( this.scene.app, this.galleryCamera ) )

    this.renderPassAbout = new RenderPass( this.scene.about, this.orthoCamera )

    this.renderPassAboutRipple = new RenderPass( this.scene.aboutRipple, this.orthoCamera ) // has to be same camera -didn't see anything render
   
    this.composer.about.addPass( this.renderPassAbout ) 
    this.composer.aboutRipple.addPass( this.renderPassAboutRipple )

    //Passes
    this.effect1 = new ShaderPass( CustomPass );
    this.effect1.uniforms[ 'progress' ].value = 1.;
    this.effect1.uniforms[ 'scale' ].value = 3.8;
    
    this.effect2 = new ShaderPass( RippleCustomPass );
    this.effect2.uniforms['uDisplacement'].value = this.composer.about.renderTarget2.texture; //both renderTarget 1 and 2 works
    
    this.composer.aboutRipple.addPass( this.effect1 );
    this.composer.aboutRipple.addPass( this.effect2 );

    // redraw with a shader - Makes color lighter, takes linear to sRGB
    this.composer.home.addPass( new ShaderPass( GammaCorrectionShader ) )

    // anti-alias
    this.composer.home.addPass( new SMAAPass( this.width_pixelRatio , this.height_pixelRatio ) );
    this.composer.app.addPass( new SMAAPass( this.width_pixelRatio , this.height_pixelRatio ) );
    this.composer.about.addPass( new SMAAPass( this.width_pixelRatio , this.height_pixelRatio ) );

  }

  outro(){
    let tl = gsap.timeline( ( {
      repeat: 0,
    } ) );
     tl
     .to( '.hero-stroke', {
      duration: .3,
      autoAlpha: 0,
    }, "<" )
    .to( ".hi", {
			duration: .3,
			autoAlpha: 0,
			x: 50
		}, "<" )
		.to( ".my-name", {
			duration: .3,
			autoAlpha: 0,
			x: 50
		},  "<"  )
		.to( ".my-title", {
			duration: .3,
			autoAlpha: 0,
		},  "<"  )
		.to( ".explore-button-wrapper", {
			duration: .3,
			autoAlpha: 0,
      y: 30
		}, "<" )
    .to(".hero-text", {
      autoAlpha: 0,
    })
    .to( this.camera.position, {
      x: 0,
      z: -6,
      duration: 1,
      onUpdate: () => this.camera.lookAt(0,7,-2), 
      onComplete: () => {
        this.loadOrbitControls()
      }
    })
    .to('.explore-tip', {
      opacity: 1,
      duration: .5,
    })
    .to('.explore-tip', {
      autoAlpha: 0,
      delay: 3,
      onComplete: () => {
        this.hideHero()
      }
    })

    tl.play();

  }  

  intro(){
    let tl = gsap.timeline( ( {
      repeat: 0,
    } ) );
     tl
     .set('.page-transition-gold', {
       scaleX: 0,
     })
     .set('.page-transition-black', {
       scaleX: 0,
     })
     .set('.page-transition-logo', {
       autoAlpha: 0
     })
    .to('.loading', {
      opacity: 0
    })
     .to('.loading-box1', {
       fill: '#66fcf1', 
       duration: .2
     })
     .to('.loading-box2', {
       fill: '#66fcf1',
       duration: .2
     })
     .to('.loading-box3', {
       fill: '#66fcf1',
       duration: .2
     })
    .to("#loading-svg", {
      delay: .2,
      opacity: 0,
    })
    .to("#overlay-loader", {
      autoAlpha: 0
    })
    .to( this.camera.position, {
      x: 2.5,
      z: -5.5,
      duration: 2,
      onUpdate: () => this.camera.lookAt( -2.5, 5, 2.5 ),
    }, "<" )
    .to( this.MODELS[ 0 ].scene.rotation, {
      y: -Math.PI * 0.5,
      duration: 1,
      ease: "none",
    }, "<" )
    .to( this.macScreen.rotation, {
      x: -Math.PI * 0.5,
      duration: 1
    }, "<" )
    .to( this.macScreen.position, {
      y: - .0001,
      z: -1.34,
      duration: 1
    }, "<" )
    .to( this.MODELS[ 5 ].scene.position, {
			y: 1.1,
			duration: .5,
		}, "-=.75" )
    .from( '.hero-stroke', {
      duration: .5,
      autoAlpha: 0,
    }, "<" )
    .from( ".hi", {
			duration: .5,
			autoAlpha: 0,
			x: 40
		}, "-=.25" )
		.from( ".my-name", {
			duration: .5,
			autoAlpha: 0,
			x: 40
		},  "<"  )
		.from( ".my-title", {
			duration: .5,
			autoAlpha: 0,
		},  "-=.25"  )
		.from( ".explore-button-wrapper", {
			duration: .5,
			autoAlpha: 0,
      y: 30
		}, "<" )

    tl.play();
  }

  // switches pages
  goto( page ){
    //console.log('switch scene', page)
    if( page === 'home'){
      this.galleryScroll = false; 
      this.enableSwirl = false;
      this.showLaptopSwirl = true;
      this.activeScene = this.scene.home
      if ( this.isOrbitEnabled ){
        this.controls.enabled = true
        this.camera.position.set(0, 8.5, -7)
        this.camera.lookAt(0, 7, -2); 
      } else {
        // orbit's preset hasn't been enabled yet
        this.loadOrbitControls()
      }
      return
    }

    if( page === 'apps'){
      this.controls.enabled = false
      this.showLaptopSwirl = false
      this.galleryCamera.position.set(2.5, 7, 1.5)
      this.galleryCamera.lookAt(-.3, 7, -1); 
      this.galleryScroll = true; 
      this.enableSwirl = false;
      this.scrollPoints.wheelSpeed = 0.001
      this.activeScene = this.scene.app
      return
    }

    if( page === 'about'){
      this.controls.enabled = false
      this.galleryScroll = false; 
      this.showLaptopSwirl = false;
      this.enableSwirl = true
      this.activeScene = this.scene.about
      return
    }
  }


  setShaderMaterial( picture, vertex, fragment ){
    return new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      //side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0.0 },
        t: { type: "f", value: picture },
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: { value: new THREE.Vector2(1, 1) }
      },
      vertexShader: vertex,
      fragmentShader: fragment,
      //transparent: true,
    })
  }

  setPlane( shaderMaterial, a, b, c, d ) {
    return new THREE.Mesh( new THREE.PlaneBufferGeometry( a, b, c, d ), shaderMaterial )
  }

  loadAppGallery(){
     // where scene 1 also begins at table
    const APPS_START_INDEX = 6;
    for (let i = 0; i < 3; i++) {
      let material = this.setShaderMaterial( this.MODELS[i + APPS_START_INDEX].asset, appVertex, appFragment );
      material.uniforms.distance = { type: 'f', value: 0.0}
      this.appMaterials.push( material )
      
      let mesh = this.setPlane( material, 1.5, 1, 20, 20)
      // also change the y pos in the render
      mesh.position.set( 0, this.HEIGHT - (i * this.GAP), 0)
      this.appMeshes.push( mesh )
      this.appGroup.add( mesh )
    }
    this.scene.app.add( this.appGroup )
    this.appGroup.rotation.set( -0.1, 0.3, -0.1)
  }

  /**
   * Runs when the position of the first app is changing and within bounds
   * @param {String} pos first app's y position
   * @returns 
   */
  calcPosColors( pos ){

    if ( pos.toFixed(3) === this.previousApp0Position.toFixed(3) ) return

    if ( pos < 7.0 || pos > 9.4 ) return

    let ratio = pos < 8.2 ? ( 8.2 - pos ) / 1.2 : ( 9.4 - pos ) / 1.2
    let bg = ''

    if( pos >= 6.8 && pos <= 8.2){
      if( this.previousApp0Position < pos ){
        // forward
        bg = lerpColor( this.appColors[0], this.appColors[1], 1 - ratio)
      } else if( this.previousApp0Position > pos ){
        // backward
        bg = lerpColor( this.appColors[1], this.appColors[0], ratio)
      }

    } else if ( pos > 8.2 && pos < 9.6 ){
      if( this.previousApp0Position < pos ){
        // forward
        bg = lerpColor( this.appColors[1], this.appColors[2], 1 - ratio)
      } else if( this.previousApp0Position > pos ){
        // backward
        bg = lerpColor( this.appColors[2], this.appColors[1], ratio)
      }
    }

    this.scene.app.background.set( bg )

  }

  setNewMouseRipple( x, y, index ){
    let mesh = this.brushMeshes[ index ]
    mesh.visible = true
    mesh.position.x = x
    mesh.position.y = y
    mesh.scale.x = mesh.scale.y = 1
    mesh.material.opacity = 1
  }

  trackMouseRipplePos(){
    if( Math.abs( this.mouseEffect.x - this.prevMouseEffect.x ) < 4 && Math.abs( this.mouseEffect.y - this.prevMouseEffect.y ) < 4){
      // do nothing if less than 4 px movements
    } else {
      this.setNewMouseRipple( this.mouseEffect.x, this.mouseEffect.y, this.currentWave )
      this.currentWave = ( this.currentWave + 1 ) % this.maxBrushes;
    }

    this.prevMouseEffect.x = this.mouseEffect.x
    this.prevMouseEffect.y = this.mouseEffect.y
  }

  update( time ){

    // home
    if ( this.showLaptopSwirl ){
      this.laptopSwirlMaterial.uniforms.time.value = time
    }

    // about me
    if ( this.enableSwirl ){
      this.trackMouseRipplePos()
      this.aboutMaterial.uniforms.time.value = time //bg swirl
      this.effect1.uniforms[ 'time' ].value = time

      //this.effect1.uniforms[ 'progress' ].value = this.settings.progress
      //this.effect1.uniforms[ 'scale' ].value = this.settings.scale
      
      // mouse
      this.brushMeshes.forEach( m => {
        if ( m.visible ) {
          m.rotation.z += 0.02;
          m.material.opacity *= 0.96; // the lower the %, faster it fades
          m.scale.x = 0.98 * m.scale.x + 0.1 // making it smaller each time and adding a bit on top, slow exponential growth
          m.scale.y = 0.98 * m.scale.y + 0.1
          if ( m.material.opacity < 0.005 ){
            m.visible = false
          }
        }
      })
    }

    // gallery scroll in APPS
    if ( this.galleryScroll && this.scrollPoints.wheelSpeed ) {
      //TODO if small screen return this side still runs when going from large to small screen
      this.scrollPoints.wheelPosition += this.scrollPoints.wheelSpeed
      this.scrollPoints.wheelSpeed *= 0.8;

      if ( this.appMaterials.length > 0 ){

        this.previousApp0Position =  this.appMeshes[0].position.y

        for ( let j = 0; j < this.appMaterials.length; ++j ) {
          let dist = Math.min( Math.abs( this.scrollPoints.wheelPosition - j ), 1)
          dist = 1 - dist ** 2; // quadratic curve
          this.scale = 1 + 0.15 * dist // how big the app img grows
  
          // parallax effect
          this.appMaterials[j].uniforms.time.value = time;
          //console.log({time})

          // MIN boundary
          if ( this.scrollPoints.wheelPosition < this.MIN ) {
            
            this.scrollPoints.wheelPosition = this.MIN
            
          } // MAX boundary
          else if ( this.scrollPoints.wheelPosition > this.MAX) {

            this.scrollPoints.wheelPosition = this.MAX
          }

          // scroll effect, revert - and + to have it scale going up instead from bottom up
          
          this.appMeshes[j].position.y = this.moveScroll ? this.appMeshes[j].position.y + this.incrementValue : this.HEIGHT - ( j * this.GAP ) + ( this.scrollPoints.wheelPosition * this.GAP )	
          

          this.appMeshes[j].scale.set( this.scale, this.scale, this.scale )
          // actually distance from center, not distortion- reusing variable
          this.appMaterials[j].uniforms.distance.value = dist;
          
        }

        this.calcPosColors( this.appMeshes[0].position.y )

        // rounded minus the wheel position
        let diff = Math.round( this.scrollPoints.wheelPosition ) - this.scrollPoints.wheelPosition;

        // create floating effect, use square root to ease the snapping
        this.scrollPoints.wheelPosition += Math.sign( diff ) * Math.pow( Math.abs( diff ), 0.7 ) * 0.02;

        	/**
			 * Scrolling through the apps
			 */

        if (this.appMeshes[0].position.y > 7.6 && this.appMeshes[0].position.y < 8.6 && this.currentApp === 0){
          //console.log(' 1 to 2')
          this.currentApp = 1
          if (this.moveScroll){
            this.scrollPoints.wheelPosition = .99
          }
          contentAnimationById( 1 )
       
        } else

        if (this.appMeshes[0].position.y > 8.8 && this.currentApp === 1){
          //console.log(' 2 to 3')
          if (this.moveScroll){
            this.scrollPoints.wheelPosition = 1.9
          }
          this.currentApp = 2
          contentAnimationById( 2 )
   
        } else

        // scrolling down
        if ( this.appMeshes[0].position.y < 8.45 && this.appMeshes[0].position.y > 7.05 && this.currentApp === 2){
          //console.log(' 3 to 2')
          this.currentApp = 1
          if (this.moveScroll){
            this.scrollPoints.wheelPosition = .99
          }
          contentAnimationById( 1 )

        } else

        if ( this.appMeshes[0].position.y < 7.05 && this.currentApp === 1){
          //console.log(' 2 to 1')
          this.currentApp = 0
          if (this.moveScroll){
            this.scrollPoints.wheelPosition = this.MIN
          }
          contentAnimationById( 0 )
  
        } 
        if (this.moveScroll) {
          if ( this.appMeshes[0].position.y >= (this.targetPosition - 0.4) && this.appMeshes[0].position.y <= (this.targetPosition + .04) ) {
            this.moveScroll = false
          }
       }

      }

    }

  }

}