import gsap from 'gsap';
import { hideCanvas, removeNavFlexDirection, showCanvas, updateHeaderFontColor, updateNavFlexDirection, nav_links } from '../main';
import { first_scene } from '../fade.js';

// queries large or smaller screen
export let mediaQuery = window.matchMedia('(max-width: 1025px)')

let startEvent = (function(){
  if ('ontouchstart' in document.documentElement === true) return 'touchstart';
  else return 'mousedown'
})()

let endEvent = (function(){
  if ('ontouchstart' in document.documentElement === true) return 'touchend';
  else return 'mouseup'
})()

let slider, app_0, leftBoundary, MIDPOINT, rightBoundary, leftBufferBoundary, reqAnim, indicator_buttons, bg_drop, large_screen_div, items, buttons, base_indicator_buttons, QUARTERPOINT;

let isTouchDevice = false;
let isSmallScreen = false;

let pressed = false; // to indicate if mouse if pressed
let selectApp = false;
const FRICTION_COEFF = 0.85; // higher, smoother the scroll
let timer = 0;
let velocity = 0;
let previous_x = 0;
let position_x = 0;
let prev_position_x = 0;
let current_x = 0;
let rounded = 0;
let text_items; // subheading for each image carousel
let slider_width = 0; // overall carousel width
let percentage = 0; // to hold where scroll position is relative to parent
const app_position = {}; // hold original opacity values for each subheading

let pageStart = 0
let pageEnd = 0
let current_app_index = 0

/**
 * Clamps the ends so scroll stays within the boundaries
 * @param {*} position scroll position
 * @param {*} min 
 * @param {*} max 
 * @returns position within the boundaries
 */
function clamp( position, min, max ){
  return Math.min( Math.max( min, position ), max )
}

/**
 * Tick function for smaller carousel
 * Larger screen carousel runs in init.three.js
 * @returns 
 */
function step() {
  
  if (selectApp){
    timer++
  }
  // handle NaN
  if(!position_x){
    position_x = 0
  }

  position_x += velocity 
  velocity *= FRICTION_COEFF // so it doesn't keep going
  //console.log({velocity})

  if ( !pressed ){  
    // makes it gravitate toward an image
    rounded = Math.round( position_x / MIDPOINT )

    let diff = position_x + ( rounded * MIDPOINT * -1 )
    //console.log({rounded, diff, MIDPOINT, position_x})
    position_x += ( -diff  * 0.05 ) // larger the right float, tougher to scroll

    //position_x += Math.sign(-diff) * Math.pow( Math.abs( -diff ), 0.7 ) * 0.05

  }

  position_x = clamp( position_x, rightBoundary, leftBufferBoundary )
  
  moveSmallCarousel( position_x )
  setCurrentApp( position_x )
  usePositionForBGColors( position_x )

  prev_position_x = position_x

  // opacity of indicator buttons and app images
  if ( text_items ){
    percentage = Number(getPercentage( position_x ) )
    let jump = 4 * percentage

    text_items.forEach( ( item, i ) => {
      let pos = 0;
        if ( i === 0 ){
          pos = app_position[i] - jump
          item.style.opacity = pos
          indicator_buttons[0].style.opacity = pos

        } else if ( i === 1 ) {
          if ( jump <= 2 ){
            pos = app_position[i] + jump
            item.style.opacity = pos
            indicator_buttons[1].style.opacity = pos
            
          } else if ( jump  > 2 && jump <= 3 ) {
            pos = 3 - jump
            item.style.opacity = pos
            indicator_buttons[1].style.opacity = pos
           
          } else {
            pos = app_position[i] + ( 4 - jump )
            item.style.opacity = pos
            indicator_buttons[1].style.opacity = pos
           
          }
          
        } else if ( i === 2 ) {
          pos = app_position[i] + jump
          item.style.opacity = pos
          indicator_buttons[2].style.opacity = pos
        }

    })
  }

  reqAnim = requestAnimationFrame(step);
}

/**
 * Calculates the position relative to carousel
 * @param {Float} value position
 * @returns 
 */
function getPercentage( value ){
  return  ( value / slider_width ).toFixed(3)
}

export function stopStep() {
  if ( reqAnim ) {
    //console.log('canceling step', reqAnim)
    window.cancelAnimationFrame(reqAnim);
  }
}

function moveSmallCarousel( pos ){
  slider.style.transform = `translateX(${pos.toFixed(3)}px)`
}

function setCurrentApp( pos ) {
  if ( pos < -(MIDPOINT + QUARTERPOINT) ){
    if ( current_app_index !== 2 ){
      current_app_index = 2
    }
    return
  }

  if ( pos < -QUARTERPOINT && current_app_index !== 1 ){
    current_app_index = 1
    return
  }

  if ( pos > -QUARTERPOINT && current_app_index !== 0){
    current_app_index = 0
    return
  }

}

/**
 * 
 * @param {String} a start hex color
 * @param {String} b end hex color
 * @param {Number} amount ratio of fade
 * @returns 
 */
export function lerpColor(a, b, amount) { 

  let ah = +a.replace('#', '0x'),
      ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
      bh = +b.replace('#', '0x'),
      br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
      rr = ar + amount * (br - ar),
      rg = ag + amount * (bg - ag),
      rb = ab + amount * (bb - ab);

  return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

// mousedown or touchstart
function touchStartMouseDown( e ){
  e.preventDefault();
  pressed = true;
  if ( e.type.includes('mouse') ){
    slider.style.cursor = 'grabbing'
    previous_x = e.x - leftBoundary
  } else {
    previous_x = e.touches[0].clientX - leftBoundary
  }
}

function touchEndMouseEnd( e ){
  e.preventDefault();
  pressed = false;
}

function touchMoveMouseMove( e ){
  if (!pressed) return
  e.preventDefault();
  if ( e.type.includes('mouse') ){
    current_x = e.x - leftBoundary
    // multiply by 1.5 for desktop or apps do not move over to next image without effort
    velocity = ( current_x - previous_x ) * 1.6
  } else {
    current_x = e.touches[0].clientX - leftBoundary
    velocity = current_x - previous_x 
  }
  previous_x = current_x
}

function touchMouseDownAppItem( e ){
  e.preventDefault()
  // mouse or touchstart
  selectApp = true  
  //console.log('mouse down')
  if ( e.type.includes('mouse') ){
    pageStart = e.x
  } else {
    pageStart = e.touches[0].pageX
  }

}

function touchMouseUpAppItem( i ){
  return function ( e ) {
    e.preventDefault()
    // mouse or touchstart
    selectApp = false  
    if ( e.type.includes('mouse') ){
      pageEnd = e.x
    } else {
      pageEnd = e.changedTouches[0].pageX
    }

    // open page if image is clicked
    if ( pageStart === pageEnd && timer < 20){
      showMoreContent( i )
    }

    //reset timer
    timer = 0
  }
  
}

export function removeListeners() {
    
    if ( isTouchDevice ){
      //console.log('removing listener for touch device')
      slider.removeEventListener('touchstart', touchStartMouseDown)

      slider.removeEventListener('touchend', touchEndMouseEnd)

      slider.removeEventListener('touchmove', touchMoveMouseMove)

      return
    }

    // Is not touch device
    if ( !isTouchDevice ) {
      //console.log('removing listener for non touch device')
      slider.removeEventListener('mousedown', touchStartMouseDown)

      slider.removeEventListener('mouseup', touchEndMouseEnd)

      slider.removeEventListener('mouseleave', touchEndMouseEnd)  
      
      slider.removeEventListener('mousemove', touchMoveMouseMove)

      slider.removeEventListener('mouseenter', mouseGrab)

      large_screen_div.removeEventListener('wheel', wheelHandler)

      large_screen_div.removeEventListener('click', castRayAppGallery)

      large_screen_div.removeEventListener('mousemove', castRayChangeCursor)
    }

}

function mouseGrab() {
  slider.style.cursor = 'grab'
}

function usePositionForBGColors( pos ){
  //let x =  pos / (slider_width * .5)
  if (pos > 0) return // keep boundary
  let x =  pos / slider_width // position on the slider
  if (!x) return // handle NaN
  let xx = x * 2 // position between start and the next app

  if ( xx > 1.00000 ) { // for app 2 to 3 interim keep between 0 and 1
    xx -= 1
  }

  if ( x < .5 ){
    bg_drop.style.background = prev_position_x > position_x ? lerpColor( first_scene.appColors[0], first_scene.appColors[1], xx) : lerpColor( first_scene.appColors[1], first_scene.appColors[0], 1.0 - xx)

    // lerp indicator buttons and header
    let val = prev_position_x > position_x ? lerpColor( "#000000", '#ffffff', xx) : lerpColor( '#ffffff', '#000000', 1.0 - xx)
    base_indicator_buttons[0].style.background = val
    base_indicator_buttons[1].style.background = val
    base_indicator_buttons[2].style.background = val
    nav_links[0].style.color = val
    nav_links[1].style.color = val
    // skip [2] is active link so color is the same
    nav_links[3].style.color = val

  } else {
    bg_drop.style.background = prev_position_x > position_x ? lerpColor( first_scene.appColors[1], first_scene.appColors[2], xx) : lerpColor( first_scene.appColors[2], first_scene.appColors[1], 1.0 - xx) 
  }

}

function initSmallScreenListeners(){

  // Is not touch device
  if ( !isTouchDevice ) {
    //console.log('activating mouse events')

    // runs once on initial down
    slider.addEventListener('mousedown', touchStartMouseDown)

    slider.addEventListener('mouseenter', mouseGrab)

    slider.addEventListener('mouseup', touchEndMouseEnd)

    slider.addEventListener('mouseleave', touchEndMouseEnd)  
    
    slider.addEventListener('mousemove', touchMoveMouseMove)

  } 
  
  if ( isTouchDevice ){

    slider.addEventListener('touchstart', touchStartMouseDown)

    slider.addEventListener('touchend', touchEndMouseEnd)

    slider.addEventListener('touchmove', touchMoveMouseMove)

  }

  slider.addEventListener('dragstart', e => {
    e.preventDefault()
  })

  // Disable context menu
  slider.oncontextmenu = function (e) {
    e.preventDefault()
    e.stopPropagation()
    return false
  }

}

function wheelHandler( e ){
  first_scene.scrollPoints.wheelSpeed += e.deltaY * 0.0004;
}

function initLargeScreenListeners(){
  //console.log('wheel listener added')
  large_screen_div.addEventListener('wheel', wheelHandler)
  large_screen_div.addEventListener('click', castRayAppGallery)
  large_screen_div.addEventListener('mousemove', castRayChangeCursor)
}

function castRayChangeCursor( e ){
    // sets mouse position with coordinate where center of the screen is the origin
    first_scene.appRayMouse.x = ( e.clientX / first_scene.sizes.width ) * 2 - 1;
    first_scene.appRayMouse.y = - ( e.clientY / first_scene.sizes.height ) * 2 + 1;

    // set the picking ray from the cam position and mouse coordinates
    first_scene.appRayCaster.setFromCamera( first_scene.appRayMouse, first_scene.galleryCamera )

    let intersect = first_scene.appRayCaster.intersectObjects( first_scene.appMeshes )

    if ( intersect.length > 0 ){
      // change cursor
      large_screen_div.style.cursor = "pointer"
    } else {
      large_screen_div.style.cursor = "default"
    }

}

function castRayAppGallery( e ){

  // sets mouse position with coordinate where center of the screen is the origin
  first_scene.appRayMouse.x = ( e.clientX / first_scene.sizes.width ) * 2 - 1;
  first_scene.appRayMouse.y = - ( e.clientY / first_scene.sizes.height ) * 2 + 1;

  // set the picking ray from the cam position and mouse coordinates
  first_scene.appRayCaster.setFromCamera( first_scene.appRayMouse, first_scene.galleryCamera )

  let intersect = first_scene.appRayCaster.intersectObjects( first_scene.appMeshes )
  //console.log('intersect', intersect)

  if ( intersect.length > 0 ){
    // open app details
    showMoreContent( first_scene.currentApp )
  }

}

/**
 * Creates the initial Dom elements for both mobile and desktop screens
 */
export const initApps = () => {
  // create the divs once
  isTouchDevice = 'ontouchstart' in document.documentElement
  initSmallCarousel()
  initLargeCarousel()

  // if small screen
  if ( mediaQuery.matches ) {
    //console.log('initiate step for small screen')
    step()
    // hide canvas
    hideCanvas()
  } else {
    // larger screen
  }
}

export const resize = () => {
  window.addEventListener('resize', () => {
    //console.log('resizing', window.innerWidth )
    if ( isSmallScreen ){
      MIDPOINT = app_0.getBoundingClientRect().width
      QUARTERPOINT = MIDPOINT * .5
      rightBoundary = MIDPOINT * -2 - ( MIDPOINT * .1 );
      leftBufferBoundary = MIDPOINT * .1;
      slider_width = -slider.getBoundingClientRect().width * 2
      //console.log('resizing to small screen', {MIDPOINT})

    } else {
      // large screen
    }
    
  })
}

export function initLargeCarousel(){
// larger screen
  large_screen_div = document.getElementById('large-apps-carousel')

  document.querySelectorAll(".apps-carousel").forEach( carousel => {

    items = carousel.querySelectorAll('.app-hero-item');
    // generate individual circular button by creating an array from the items - callback runs for every item in the list
    const buttonsHtml = Array.from( items, () => {
      return `<div class="indicator-button"></div>`;
    } );

    // insert the buttons
    carousel.insertAdjacentHTML("beforeend", `
      <div class="app-carousel-nav">
        <div class="app-indicator-wrapper">
        <div class="app-indicator-flex">
          <div class="app-indicator-buttons">
            ${ buttonsHtml.join("") }
          </div>
          </div>
        </div>
      </div>
    `)

  // activate the buttons
  buttons = carousel.querySelectorAll(".indicator-button");
  buttons.forEach( ( button, i ) => {
    button.addEventListener( 'click', () => {
      if ( i === first_scene.currentApp ) return

      // scroll to app image
      first_scene.moveScroll = true
      first_scene.targetPosition = first_scene.HEIGHT + ( i * first_scene.GAP)

      if( i > first_scene.currentApp){
        first_scene.incrementValue = 0.08
      } else if ( i < first_scene.currentApp){
        first_scene.incrementValue = -0.08
      }
    })

    // set indicator buttons to darker color on intial load
    button.style.background = 'rgba(13, 13, 13, .4)'
  })

  // activate sub heading buttons that shows another div with content
  const subButtons = carousel.querySelectorAll(".app-cta")
  subButtons.forEach( ( subButton, i ) => {
    subButton.addEventListener( 'click', () => {
      showMoreContent( i )
    })
  })

    // select the first item by default on page load
  items[first_scene.currentApp].classList.add("app-hero-item-selected")
  buttons[first_scene.currentApp].style.background = first_scene.appHeadingColors[first_scene.currentApp]
  contentAnimation()

  })

  // activate the back to gallery button 
  const backSubButtons = document.querySelectorAll(".app-nav-back")
  backSubButtons.forEach( ( back, i ) => {
    back.addEventListener( 'click', () => {
      goBackToAppMain( i )
    })
  } )

  initLargeScreenListeners()
  
}


export function initSmallCarousel(){
  bg_drop = document.getElementById('apps-image-background')
// smaller screens
  document.querySelectorAll(".apps-image-carousel").forEach( images => {
    // select carousel items
    const image_items = images.querySelectorAll(".apps-image-wrapper");

    // create nav buttons from the number of carousel items and insert at end of carousel block
    const image_nav_buttons = Array.from( image_items, () => {
      return `<div class="smaller-screen-indicator-button"></div>`;
    })
    const image_nav_buttons_overlay = Array.from( image_items, ( x, i ) => {
      return `<div id="smaller-screen-indicator-button-overlay-${i}" class="smaller-screen-indicator-button-overlay"></div>`;
    })

    images.insertAdjacentHTML("beforeend", `
      <div class="smaller-screen-app-carousel-nav">
          <div class="smaller-screen-app-indicator-buttons">
            ${ image_nav_buttons.join("") }
          </div>
      </div>
      <div class="smaller-screen-app-carousel-nav-overlay">
          <div class="smaller-screen-app-indicator-buttons-overlay">
            ${ image_nav_buttons_overlay.join("") }
          </div>
      </div>
    `)

    // select carousel item subheadings and set the initial opacity 
    text_items = images.querySelectorAll(".apps-image-subtext-wrapper");

      text_items.forEach( ( item, i ) => {
        item.style.opacity =  -2 * i + 1
        app_position[i] = -2 * i + 1
      } )

    // Item to open page with app details
    image_items.forEach( ( item, i ) => {
      
      item.addEventListener( startEvent , touchMouseDownAppItem )
    
      item.addEventListener( endEvent , touchMouseUpAppItem( i ) )
      
    })

    // nav buttons should open up description page
    // buttons that change color to respective app theme
    indicator_buttons = images.querySelectorAll(".smaller-screen-indicator-button-overlay")

    // light or dark button underneath
    base_indicator_buttons = images.querySelectorAll(".smaller-screen-indicator-button")

    // select first item - does this do anything?
    indicator_buttons[0].classList.add("smaller-screen-indicator-button-selected")

  })  

  slider = document.querySelector('.apps-image-container');
  slider_width = - slider.getBoundingClientRect().width * 2
  app_0 = document.getElementById('apps-image-0')
  leftBoundary = slider.offsetLeft; // where carousel begins
  MIDPOINT = app_0.getBoundingClientRect().width 
  QUARTERPOINT = MIDPOINT * .5
  rightBoundary = MIDPOINT * -2 - ( MIDPOINT * .1 );
  leftBufferBoundary = MIDPOINT * .1; // point before bounces back

  initSmallScreenListeners()
  resize();

}



/**
 * 
 * @param {*} item_index app's index
 */
function showMoreContent( item_index ){
  let tl = gsap.timeline();
  tl.to(`#apps-${item_index}`, {
    duration: .5,
    display: 'block',
    bottom: 0
  }).set('.webgl', {
    visibility: "hidden"
  })
}

/**
 * 
 * @param {*} item_index 
 */
function goBackToAppMain( item_index ) {
  let tl = gsap.timeline();
  tl
  .set('.webgl', {
    visibility: 'visible'
  })
  .set('.app-transition', {
    backgroundColor: first_scene.appHeadingColors[item_index]
  })
  .to('.app-transition', {
    duration: 1,
    display: 'block',
    right: "100%"
  }).set(`#apps-${item_index}`, {
    display: 'none',
    bottom: '-100%'
  }, ".3")
  .set('.app-transition', {
    display: 'none',
    right: '-100%'
  })

}

/**
 * Fades in selected app's sub heading
 */
function contentAnimation(){
  let tl = gsap.timeline();
  tl.from(".app-hero-item-selected", {
    duration: 1,
    y: 30,
    opacity: 0,
    stagger: 0.4,
    delay: 0.2
  })
}

/**
 * Animates the Larger Screen Carousel from init_three.js
 * @param {Number} id 
 */
export function contentAnimationById( id ){
 
  items.forEach( ( item, i ) => { 
    item.classList.remove("app-hero-item-selected") 

    if ( id === 0 ){
      buttons[i].style.background = 'rgba(13, 13, 13, .4)'
    } else {
      buttons[i].style.background = 'rgba(255, 255, 255, .4)'
    }

  })

  buttons[id].style.background = first_scene.appHeadingColors[id]

  if(id === 0){
    // sets header to horizonal
    removeNavFlexDirection()
  }

  if(id === 1){
    // sets header to vertical for scroll visibility
    updateNavFlexDirection( 'vertical-rl' )
  }

  // hides the others
  items[id].classList.add("app-hero-item-selected")

  if(id === 0){
    updateHeaderFontColor( '#000000' )
  }
  if(id === 1){
    updateHeaderFontColor( '#ffffff' )
  }


  //buttons[id].classList.add("indicator-button-selected")
  buttons[id].style.background = first_scene.appHeadingColors[id]
  contentAnimation()

}

/**
 * Runs on App screen
 */

// listens for media query status changing- max-width: 1025px
export function addMediaQueryEventHandler(){
  mediaQuery.addEventListener( 'change', handleCarouselScreenChange )
}

export function removeMediaQueryListener(){
  mediaQuery.removeEventListener( 'change', handleCarouselScreenChange )
}

function matchLargeScreenAppSelection(){

  if ( first_scene.currentApp == 0 ) {
    position_x = -0.5
  }

  if ( first_scene.currentApp === 1 ){
    position_x = -MIDPOINT + 0.5
    return
  }

  if ( first_scene.currentApp === 2 ){
    position_x = -MIDPOINT * 2
    return
  }
}

function matchSmallScreenAppSelection(){
  if ( current_app_index === 0 ){
    first_scene.scrollPoints.wheelPosition = 0.05
    return
  }
  if ( current_app_index === 1 ){
    first_scene.scrollPoints.wheelPosition = .95
    updateNavFlexDirection( 'vertical-rl' )
    updateHeaderFontColor( '#ffffff' )
    return
  }
  if ( current_app_index === 2 ){
    first_scene.scrollPoints.wheelPosition = 1.95
    updateNavFlexDirection( 'vertical-rl' )
    updateHeaderFontColor( '#ffffff' )
    return
  }


}

// Runs when screen size changes between small and large
export function handleCarouselScreenChange( e ){
  // max-width: 1025px and is not small screen
  if ( e.matches ) {
    //console.log('is small screen')
    // is it touch device?
    isTouchDevice = 'ontouchstart' in document.documentElement
    //console.log({isTouchDevice})
    MIDPOINT = app_0.getBoundingClientRect().width
    rightBoundary = MIDPOINT * -2 - ( MIDPOINT * .1 );
    leftBufferBoundary = MIDPOINT * .1;
    slider_width = -slider.getBoundingClientRect().width * 2

    step()
    // hide the canvas
    hideCanvas()
    matchLargeScreenAppSelection()
    // reset
    removeNavFlexDirection()

    isSmallScreen = true
    return
  } 
  //console.log('is large screen')
  // desktop screen size - larger than 1025px
  stopStep()
  // show the canvas
  showCanvas()
  // use the canvas and listen for mouse events from the main script

  matchSmallScreenAppSelection()

  isSmallScreen = false

}